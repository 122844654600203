import { Link } from "gatsby"
import React from "react"
import TobogganLogo from "./tobogganlogo"

import * as headerCSS from "./header.module.sass"

const Header = ({ inverted }) => {
  
  return (
    <header className={headerCSS.header}>
      <Link to="/" className={headerCSS.home}>
        <TobogganLogo inverted={inverted} />
      </Link>
    </header>
  )
}

export default Header