/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 * 
 * BJ Note: We've implemented Layout using gatsby-plugin-layout, which implements Gatsby V1-style layout, applying the layout at the top level and wrapping each of our /pages/ so we can maintain state and animations (in this case, the rotating Header image)
 * 
 */

import React from "react"
// import PropTypes from "prop-types"
import debounce from 'lodash.debounce';
import Header from "./header"
import Locations from "./locations"
import ContactInfo from "./contactinfo"
import "./toboggan.sass"
import * as layoutCSS from "./layout.module.sass"

export default class Layout extends React.Component {

  constructor(props) {
    super(props);  // not needed unless we need to access props directly within constructor
    this.state = {
      // backgroundColor: 'white',
      inverted: false,
      // width: 0,
      // height: 0
      // logoLoaded: false,
    };
    this.invertColor = this.invertColor.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.debouncedScrollListener = debounce(this.onScroll, 100, {
      leading: false,
      trailing: true
    });

  }

  componentDidMount() {
    window.addEventListener('scroll', this.debouncedScrollListener, false);
  }

  componentWillUnmount() {
    this.invertColor('white');
    window.removeEventListener('scroll', this.debouncedScrollListener);
  }

  onScroll() {
    this.invertColor();
  }

  invertColor() {
    this.setState({ 'inverted': !(window.pageYOffset < window.innerHeight * 2/3) });
  }

  render() {
    const { children } = this.props
    const { inverted } = this.state

    return (
      <>
        <Header inverted={this} />
        <main className={[layoutCSS.layout, inverted === true ? layoutCSS.inverted : '' ].join(' ')}>{children}</main>
        <Locations inverted={inverted} />
        <ContactInfo inverted={inverted} />
      </>
    )
  }
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }