import React from "react"
import * as contactCSS from "./contactinfo.module.sass"

const ContactInfo = ({ inverted }) => (
  <a
    className={[
      contactCSS.contactinfo,
      inverted === true ? contactCSS.inverted : "",
    ].join(" ")}
    href="mailto:hello@toboggan.co"
  >
    SAY HI
    {" "}
    <span role="img" aria-label="Waving hand">
      👋
    </span>
  </a>
)

export default ContactInfo
