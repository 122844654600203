import React from "react"

import * as logoCSS from "./tobogganlogo.module.sass"
// import { ReactComponent as TobogganSVG } from "../images/toboggan-logo.svg"
import TobogganSVG from "../images/toboggan-logo.svg"

const TobogganLogo = ({ inverted }) => {
  const className = `${logoCSS.logo} ${inverted === true ? logoCSS.inverted : ''}`.trim()
  // return <TobogganSVG className={className} />
  return <img src={TobogganSVG} alt="Toboggan Studios Logo" className={className} />
  // return <p>haha</p>
}

export default TobogganLogo